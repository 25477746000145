import React, { useEffect } from "react";
import { Box, Typography, Paper } from "@mui/material";
import { motion } from "framer-motion";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const { loginWithGoogle, currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // Automatically navigate to the dashboard if logged in
    if (currentUser) {
      navigate("/");
    }
  }, [currentUser, navigate]);

  const handleGoogleLogin = async () => {
    try {
      await loginWithGoogle();
      // No need to navigate here; `useEffect` will handle it
    } catch (error) {
      console.error("Error logging in with Google:", error);
    }
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        background: "linear-gradient(135deg, #1c1c1c 0%, #333333 100%)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        p: 4,
      }}
    >
      {/* Background motion effect */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.1 }}
        transition={{ duration: 1.2, delay: 0.3 }}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background:
            "url('https://images.unsplash.com/photo-1572101904986-e03b60bc8741?ixlib=rb-4.0.3&auto=format&fit=crop&w=1300&q=80') center/cover",
          zIndex: -1,
          filter: "blur(10px)",
        }}
      />

      <motion.div
        initial={{ opacity: 0, y: 60 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        style={{ width: "100%", maxWidth: "400px" }}
      >
        {/* Card for login */}
        <Paper
          elevation={10}
          sx={{
            p: 5,
            textAlign: "center",
            bgcolor: "rgba(20, 20, 20, 0.9)",
            backdropFilter: "blur(6px)",
            borderRadius: "16px",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              mb: 3,
              fontFamily: "'Montserrat', sans-serif",
              fontWeight: 800,
              background: "linear-gradient(135deg, #e63946, #f4a261)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Welcome to Shield Vault
          </Typography>

          <motion.div
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            transition={{ type: "spring", stiffness: 300 }}
            style={{
              display: "inline-block",
              marginTop: "24px",
            }}
          >
            <button
              onClick={handleGoogleLogin}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                maxWidth: "280px",
                padding: "10px 20px",
                background: "#fff",
                color: "#757575",
                fontWeight: 500,
                fontSize: "14px",
                borderRadius: "8px",
                border: "1px solid #ddd",
                cursor: "pointer",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                transition: "transform 0.1s ease-in-out",
              }}
            >
              <img
                src="https://developers.google.com/identity/images/g-logo.png"
                alt="Google Logo"
                style={{ width: "18px", height: "18px", marginRight: "10px" }}
              />
              Sign in with Google
            </button>
          </motion.div>
        </Paper>
      </motion.div>
    </Box>
  );
};

export default Login;

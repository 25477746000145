import React from "react";
import { Box, Typography } from "@mui/material";
import { motion } from "framer-motion";
import BackToDashboardButton from "../components/BackToDashboardButton";

function NetworkScanning() {
  return (
    <Box
      sx={{
        p: 4,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        bgcolor: "background.default",
        color: "text.primary",
      }}
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        <Typography
          variant="h3"
          sx={{
            mb: 2,
            textAlign: "center",
            background: "linear-gradient(to right, #90caf9, #f48fb1)",
            WebkitBackgroundClip: "text",
            color: "transparent",
          }}
        >
          Network Scanning
        </Typography>
        <Typography
          variant="body1"
          sx={{
            textAlign: "center",
            maxWidth: "600px",
            color: "text.secondary",
          }}
        >
          Analyze your network for vulnerabilities.
        </Typography>
      </motion.div>
      <BackToDashboardButton />
    </Box>
  );
}

export default NetworkScanning;

// /src/components/DecryptingText.js

import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";

function DecryptingText({ 
  text = "SCANNING...", 
  onComplete 
}) {
  const [displayText, setDisplayText] = useState("");
  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890!@#$%^&*()_+";

  useEffect(() => {
    let iterations = 0;
    const interval = setInterval(() => {
      setDisplayText(() => {
        return text
          .split("")
          .map((char, idx) => {
            // If we've locked in this character, return it
            if (idx < iterations) {
              return char;
            }
            // Else pick a random character
            return letters[Math.floor(Math.random() * letters.length)];
          })
          .join("");
      });

      iterations += 1;

      // Once we pass the final character, stop and call onComplete if provided
      if (iterations > text.length) {
        clearInterval(interval);
        if (onComplete) onComplete();
      }
    }, 50);

    // Cleanup
    return () => clearInterval(interval);
  }, [text, onComplete]);

  return (
    <Typography
      variant="h6"
      sx={{ 
        color: "#e63946", 
        fontFamily: "'Courier New', monospace" 
      }}
    >
      {displayText}
    </Typography>
  );
}

export default DecryptingText;

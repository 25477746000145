import React, { useState } from "react";
import {
  Box,
  Button,
  Modal,
  Typography,
  TextField,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ScheduleScanModal = ({ open, handleClose, handleSchedule }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [email, setEmail] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [timeZone, setTimeZone] = useState("UTC");

  const handleSubmit = () => {
    if (!searchTerm || !email || !date || !time || !timeZone) {
      alert("All fields are required.");
      return;
    }

    const localDateTime = `${date}T${time}`;
    console.log("Local DateTime:", localDateTime);

    if (isNaN(new Date(localDateTime).getTime())) {
      alert("Invalid date or time format. Please correct your input.");
      return;
    }

    const fullDateTime = new Date(
      new Date(localDateTime).toLocaleString("en-US", { timeZone })
    ).toISOString();

    console.log({
      searchTerm,
      email,
      fullDateTime,
    });

    handleSchedule(searchTerm, email, fullDateTime);
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="schedule-scan-modal"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          p: 4,
          borderRadius: 2,
          boxShadow: 24,
          width: 400,
          overflow: "hidden",
        }}
      >
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            color: "#e63946",
          }}
        >
          <CloseIcon />
        </IconButton>

        <Typography
          variant="h6"
          sx={{
            mb: 2,
            textAlign: "center",
            fontFamily: "'Montserrat', sans-serif",
            fontWeight: 700,
            background: "linear-gradient(135deg, #e63946, #f4a261)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Schedule a Scan
        </Typography>

        <TextField
          fullWidth
          label="Search Term"
          placeholder="Search a domain, URL, Email, IP, and more..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="Email Address"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ mb: 2 }}
        />

        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>Date</InputLabel>
          <Select value={date} onChange={(e) => setDate(e.target.value)}>
            {[...Array(31)].map((_, i) => {
              const day = String(i + 1).padStart(2, "0");
              return (
                <MenuItem key={day} value={`2025-01-${day}`}>
                  January {day}, 2025
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>Time</InputLabel>
          <Select value={time} onChange={(e) => setTime(e.target.value)}>
            {Array.from({ length: 24 }, (_, hour) =>
              Array.from({ length: 12 }, (_, index) => {
                const formattedHour = String(hour).padStart(2, "0");
                const formattedMinute = String(index * 5).padStart(2, "0");
                return (
                  <MenuItem key={`${formattedHour}:${formattedMinute}`} value={`${formattedHour}:${formattedMinute}`}>
                    {`${formattedHour}:${formattedMinute}`}
                  </MenuItem>
                );
              })
            )}
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>Time Zone</InputLabel>
          <Select value={timeZone} onChange={(e) => setTimeZone(e.target.value)}>
            <MenuItem value="UTC">UTC</MenuItem>
            <MenuItem value="America/New_York">Eastern (EST)</MenuItem>
            <MenuItem value="America/Chicago">Central (CST)</MenuItem>
            <MenuItem value="America/Denver">Mountain (MST)</MenuItem>
            <MenuItem value="America/Los_Angeles">Pacific (PST)</MenuItem>
          </Select>
        </FormControl>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mt: 2,
          }}
        >
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{
              backgroundColor: "#e63946",
              "&:hover": { backgroundColor: "#c72c3e" },
            }}
          >
            Schedule
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ScheduleScanModal;

import React, { useState } from "react";
import axios from "axios";
import {
  Box,
  TextField,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Alert,
  CircularProgress,
  IconButton,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { motion } from "framer-motion";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
// Pull in the real Firebase User via currentUser
import { useAuth } from "../context/AuthContext";

const DarkWebRecon = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth(); // This is the raw user now
  const [searchTerms, setSearchTerms] = useState({
    email: "",
    username: "",
    password: "",
    lastip: "",
    name: "",
    _domain: "",
  });
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [visibleColumns, setVisibleColumns] = useState([
    "email",
    "username",
    "password",
    "lastip",
    "name",
    "_domain",
  ]);

  const allTypes = Object.keys(searchTerms);

  const handleSearch = async () => {
    // Build up a list of non-empty search terms
    const terms = Object.entries(searchTerms)
      .filter(([_, value]) => value.trim() !== "")
      .map(([key, value]) => ({ type: key, term: value }));

    if (terms.length === 0) {
      setError("Please enter at least one search field.");
      return;
    }

    if (!currentUser) {
      setError("You must be logged in to perform this search.");
      return;
    }

    setError("");
    setLoading(true);
    setResults([]);

    try {
      // Here is the fix: the real User object has getIdToken()
      const idToken = await currentUser.getIdToken();

      const response = await axios.post(
        "https://proxysnusbase-ydqv7zdweq-uc.a.run.app", // Your Cloud Function URL
        {
          terms: terms.map((t) => t.term),
          types: terms.map((t) => t.type),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      if (response.data && response.data.results) {
        const parsedResults = Object.values(response.data.results).flat();
        setResults(parsedResults);
      } else {
        setError("No results found.");
      }
    } catch (err) {
      console.error("Error during search:", err);
      setError("An error occurred while fetching data.");
    } finally {
      setLoading(false);
    }
  };

  const handleExportCSV = (allColumns = false) => {
    if (results.length === 0) {
      setError("No data available to export.");
      return;
    }

    const columnsToExport = allColumns ? allTypes : visibleColumns;

    const csvData = results.map((result) =>
      Object.fromEntries(
        columnsToExport.map((col) => [
          col.charAt(0).toUpperCase() + col.slice(1),
          result[col] || "N/A",
        ])
      )
    );

    const csvContent = [
      Object.keys(csvData[0]).join(","), // Header row
      ...csvData.map((row) => Object.values(row).join(",")), // Data rows
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "darkweb_results.csv");
    link.style.visibility = "hidden";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const filteredResults = results.map((result) =>
    Object.fromEntries(
      Object.entries(result).filter(([key]) => visibleColumns.includes(key))
    )
  );

  return (
    <Box
      sx={{
        p: 4,
        minHeight: "100vh",
        bgcolor: "background.default",
        color: "text.primary",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <IconButton
        onClick={() => navigate("/")}
        sx={{
          alignSelf: "flex-start",
          mb: 2,
        }}
      >
        <ArrowBackIcon sx={{ fontSize: "2rem", color: "#e63946" }} />
      </IconButton>

      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <Typography
          variant="h3"
          sx={{
            mb: 3,
            textAlign: "center",
            fontFamily: "'Montserrat', sans-serif",
            fontWeight: 700,
            fontSize: "2.5rem",
            background: "linear-gradient(135deg, #e63946, #f4a261)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Dark Web Recon
        </Typography>
      </motion.div>

      <Box
        sx={{
          display: "grid",
          gap: 2,
          mb: 4,
          width: "100%",
          maxWidth: "600px",
          gridTemplateColumns: "1fr 1fr",
        }}
      >
        {allTypes.map((type) => (
          <TextField
            key={type}
            label={`Search by ${type}`}
            value={searchTerms[type]}
            onChange={(e) =>
              setSearchTerms((prev) => ({ ...prev, [type]: e.target.value }))
            }
          />
        ))}
      </Box>

      <Button
        variant="contained"
        onClick={handleSearch}
        sx={{
          backgroundColor: "#e63946",
          "&:hover": { backgroundColor: "#c72c3e" },
          mb: 3,
        }}
      >
        Search
      </Button>

      <FormControl sx={{ mb: 3, maxWidth: "600px", width: "100%" }}>
        <InputLabel>Filter Results</InputLabel>
        <Select
          multiple
          value={visibleColumns}
          onChange={(e) => setVisibleColumns(e.target.value)}
          renderValue={(selected) => selected.join(", ")}
        >
          {allTypes.map((column) => (
            <MenuItem key={column} value={column}>
              <Checkbox checked={visibleColumns.includes(column)} />
              {column}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {error && (
        <Alert severity="error" sx={{ mb: 4, maxWidth: "600px" }}>
          {error}
        </Alert>
      )}

      {loading && <CircularProgress sx={{ mb: 4 }} />}

      {filteredResults.length > 0 && (
        <>
          <Box sx={{ display: "flex", gap: 2, mb: 3 }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleExportCSV(false)}
            >
              Export Filtered
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleExportCSV(true)}
            >
              Export All
            </Button>
          </Box>

          <TableContainer component={Paper} sx={{ maxWidth: "1000px" }}>
            <Table>
              <TableHead>
                <TableRow>
                  {visibleColumns.map((col) => (
                    <TableCell key={col}>
                      {col.charAt(0).toUpperCase() + col.slice(1)}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredResults.map((result, index) => (
                  <TableRow key={index}>
                    {visibleColumns.map((col) => (
                      <TableCell key={col}>{result[col] || "N/A"}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Box>
  );
};

export default DarkWebRecon;

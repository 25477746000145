import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  CircularProgress,
  TextField,
  List,
  ListItem,
  IconButton,
  Alert,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { motion } from "framer-motion";
import { useAuth } from "../context/AuthContext";
import { useClient } from "../context/ClientContext"; // Import useClient for client selection
import Chart from "react-apexcharts";
import { getDoc, doc, updateDoc, arrayUnion, arrayRemove } from "firebase/firestore";
import { db } from "../context/firebase";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const CLOUD_FUNCTION_URL = "https://proxysnusbase-ydqv7zdweq-uc.a.run.app";

const DarkWebMonitoring = () => {
  const theme = useTheme();
  const { currentUser } = useAuth();
  const { selectedClient } = useClient(); // Access selected client from ClientContext
  const navigate = useNavigate(); // For back button navigation
  const [isLoading, setIsLoading] = useState(true);
  const [metrics, setMetrics] = useState([]);
  const [anchorPoints, setAnchorPoints] = useState([]);
  const [newAnchorPoint, setNewAnchorPoint] = useState("");
  const [error, setError] = useState("");

  const chartData = {
    series: [
      {
        name: "Exposed Records",
        data: metrics?.map((metric) => metric.count) || [],
      },
    ],
    options: {
      chart: {
        type: "line",
        height: 350,
        toolbar: { show: false },
      },
      xaxis: {
        categories: metrics?.map((metric) => metric.date) || [],
      },
      theme: {
        mode: theme.palette.mode,
      },
      dataLabels: { enabled: false },
      stroke: { curve: "smooth" },
    },
  };

  useEffect(() => {
    const fetchClientData = async () => {
      setIsLoading(true);
      try {
        if (!selectedClient) {
          console.warn("No client selected.");
          return;
        }
        const clientDoc = await getDoc(doc(db, "clients", selectedClient));
        if (clientDoc.exists()) {
          const clientData = clientDoc.data();
          setAnchorPoints(clientData.anchorPoints || []);
          setMetrics(clientData.metrics || []);
        } else {
          console.warn("No data found for the selected client.");
        }
      } catch (error) {
        console.error("Error fetching client data:", error);
        setError("Failed to fetch client data.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchClientData();
  }, [selectedClient]);

  const handleRunScan = async () => {
    if (anchorPoints.length === 0) {
      alert("No anchor points available. Please add anchor points first.");
      return;
    }

    if (!currentUser) {
      setError("You must be logged in to perform this scan.");
      return;
    }

    setIsLoading(true);
    setError("");
    let totalRecords = 0;

    try {
      // Prepare payload for the Cloud Function
      const payload = {
        terms: anchorPoints, // Array of anchor points
        types: ["email", "username", "password", "lastip", "name", "_domain"], // All fields
      };

      // Get Firebase ID token
      const idToken = await currentUser.getIdToken();

      // Make POST request to the Cloud Function
      const response = await axios.post(
        CLOUD_FUNCTION_URL,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      // Handle response from the Cloud Function
      if (response.data && response.data.results) {
        const resultsArray = Object.values(response.data.results).flat();
        totalRecords = resultsArray.length;

        console.log("Scan results:", resultsArray);

        // Update Firestore with the total exposed records
        const newMetric = {
          date: new Date().toISOString().split("T")[0],
          count: totalRecords,
        };

        await updateDoc(doc(db, "clients", selectedClient), {
          metrics: arrayUnion(newMetric),
        });

        setMetrics((prev) => [...prev, newMetric]); // Update local state
      } else {
        setError("No results found.");
      }
    } catch (error) {
      console.error("Error running scan:", error);
      setError("An error occurred while running the scan.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddAnchorPoint = async () => {
    if (!newAnchorPoint.trim()) return;
    try {
      await updateDoc(doc(db, "clients", selectedClient), {
        anchorPoints: arrayUnion(newAnchorPoint.trim()),
      });
      setNewAnchorPoint("");
      setAnchorPoints((prev) => [...prev, newAnchorPoint.trim()]); // Update local state
    } catch (error) {
      console.error("Error adding anchor point:", error);
      setError("Failed to add anchor point.");
    }
  };

  const handleRemoveAnchorPoint = async (anchorPoint) => {
    try {
      await updateDoc(doc(db, "clients", selectedClient), {
        anchorPoints: arrayRemove(anchorPoint),
      });
      setAnchorPoints((prev) => prev.filter((point) => point !== anchorPoint)); // Update local state
    } catch (error) {
      console.error("Error removing anchor point:", error);
      setError("Failed to remove anchor point.");
    }
  };

  return (
    <Box
      sx={{
        p: 4,
        minHeight: "100vh",
        bgcolor: "background.default",
        color: "text.primary",
      }}
    >
      {/* Back Button */}
      <IconButton
        onClick={() => navigate("/")}
        sx={{
          alignSelf: "flex-start",
          mb: 2,
        }}
      >
        <ArrowBackIcon sx={{ fontSize: "2rem", color: "#e63946" }} />
      </IconButton>

      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        style={{ textAlign: "center", marginBottom: "2rem" }}
      >
        <Typography
          variant="h3"
          sx={{
            fontFamily: "'Montserrat', sans-serif",
            fontWeight: 700,
            background: "linear-gradient(135deg, #e63946, #f4a261)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Dark Web Monitoring
        </Typography>
        <Typography
          variant="body1"
          sx={{ mt: 1, color: "#aaa", fontSize: "1rem" }}
        >
          Track and monitor exposed data for your domain over time.
        </Typography>
      </motion.div>

      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "300px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {error && (
            <Alert severity="error" sx={{ mb: 4, maxWidth: "600px", mx: "auto" }}>
              {error}
            </Alert>
          )}
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Card
                sx={{
                  bgcolor: "background.paper",
                  boxShadow: "0 4px 12px rgba(0,0,0,0.2)",
                  borderRadius: "12px",
                  textAlign: "center",
                }}
              >
                <CardContent>
                  <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                    Total Anchor Points
                  </Typography>
                  <Typography
                    variant="h3"
                    sx={{
                      mt: 2,
                      color: "primary.main",
                      fontWeight: 700,
                    }}
                  >
                    {anchorPoints.length}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card
                sx={{
                  bgcolor: "background.paper",
                  boxShadow: "0 4px 12px rgba(0,0,0,0.2)",
                  borderRadius: "12px",
                  textAlign: "center",
                }}
              >
                <CardContent>
                  <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                    Total Scans Run
                  </Typography>
                  <Typography
                    variant="h3"
                    sx={{
                      mt: 2,
                      color: "primary.main",
                      fontWeight: 700,
                    }}
                  >
                    {metrics.length}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card
                sx={{
                  bgcolor: "background.paper",
                  boxShadow: "0 4px 12px rgba(0,0,0,0.2)",
                  borderRadius: "12px",
                  textAlign: "center",
                }}
              >
                <CardContent>
                  <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                    Latest Exposed Records
                  </Typography>
                  <Typography
                    variant="h3"
                    sx={{
                      mt: 2,
                      color: "primary.main",
                      fontWeight: 700,
                    }}
                  >
                    {metrics[metrics.length - 1]?.count || 0}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Box sx={{ mt: 4 }}>
            <Chart
              options={chartData.options}
              series={chartData.series}
              type="line"
              height={350}
            />
          </Box>

          <Box sx={{ mt: 4 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Manage Anchor Points
            </Typography>
            <TextField
              value={newAnchorPoint}
              onChange={(e) => setNewAnchorPoint(e.target.value)}
              placeholder="Add an anchor point"
              fullWidth
              sx={{ mb: 2 }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    color="primary"
                    onClick={handleAddAnchorPoint}
                    disabled={!newAnchorPoint.trim()}
                  >
                    <AddCircleOutlineIcon />
                  </IconButton>
                ),
              }}
            />
            <List>
              {anchorPoints.map((point, index) => (
                <ListItem
                  key={index}
                  secondaryAction={
                    <IconButton edge="end" onClick={() => handleRemoveAnchorPoint(point)}>
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  {point}
                </ListItem>
              ))}
            </List>
          </Box>

          <Box sx={{ textAlign: "center", mt: 4 }}>
            <Button
              variant="contained"
              onClick={handleRunScan}
              sx={{
                bgcolor: "primary.main",
                "&:hover": { bgcolor: "primary.dark" },
                fontWeight: "bold",
              }}
              disabled={isLoading}
            >
              {isLoading ? "Running Scan..." : "Run Scan"}
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default DarkWebMonitoring;

// CTM.js
import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { motion } from "framer-motion";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ScheduleScanModal from "../components/ScheduleScanModal";
import DecryptingText from "../components/DecryptingText";

// 1) Import useAuth to access currentUser
import { useAuth } from "../context/AuthContext";

const CTM = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth(); // Access the authenticated user

  const [searchMode, setSearchMode] = useState("general");
  const [keywords, setKeywords] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [summary, setSummary] = useState("");
  const [darkWebTerms, setDarkWebTerms] = useState({
    email: "",
    username: "",
    password: "",
    lastip: "",
    name: "",
    _domain: "",
  });
  const [darkWebResults, setDarkWebResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [openScheduleModal, setOpenScheduleModal] = useState(false);

  const FLASK_API_URL = "https://139.144.31.16:8891";
  // Removed SNUSBASE_API_URL and SNUSBASE_API_KEY as they are no longer needed

  // Cloud Function URL (ensure this is correctly set)
  const CLOUD_FUNCTION_URL = "https://proxysnusbase-ydqv7zdweq-uc.a.run.app";

  const handleSearch = async () => {
    setError("");
    setLoading(true);

    if (searchMode === "general") {
      // General search logic remains unchanged
      if (!keywords.trim()) {
        setError("Please enter keywords for searching.");
        setLoading(false);
        return;
      }

      try {
        const response = await axios.post(`${FLASK_API_URL}/search`, { keywords });
        const data = response.data || {};

        console.log("API Response:", data);

        // Convert the object of arrays into a structure our UI can map over easily
        const resultsArray = Object.entries(data).map(([source, results]) => ({
          source,
          results: Array.isArray(results) ? results : [],
        }));

        setSearchResults(resultsArray);

        // Build a quick "formattedFindings" to pass to summarization if you like
        const formattedFindings = resultsArray
          .map(
            ({ source, results }) =>
              `${source.toUpperCase()}:\n${results
                .map((result) => {
                  // Quick fallback approach for display
                  return `- ${result.title || result.value || result.ip || "N/A"}: ${
                    result.url || result.port || "No URL/Port"
                  }`;
                })
                .join("\n")}`
          )
          .join("\n\n");

        console.log("Formatted Findings for Summarization:", formattedFindings);

        // Summarize (Optional)
        const summaryResponse = await axios.post(`${FLASK_API_URL}/summarize`, {
          findings: formattedFindings,
        });

        if (summaryResponse.data?.executive_summary) {
          setSummary(summaryResponse.data.executive_summary);
        } else {
          setSummary("Summary generation failed.");
        }
      } catch (err) {
        console.error("Error during search or summarization:", err);
        setError(err.response?.data?.error || "Summaries for findings are only available in Scheduled Scans at the moment.");
      }
    } else if (searchMode === "darkweb") {
      // Dark web search logic updated to use Cloud Function
      const terms = Object.entries(darkWebTerms)
        .filter(([_, value]) => value.trim() !== "")
        .map(([key, value]) => ({ type: key, term: value }));

      if (terms.length === 0) {
        setError("Please enter at least one search field.");
        setLoading(false);
        return;
      }

      if (!currentUser) {
        setError("You must be logged in to perform a dark web search.");
        setLoading(false);
        return;
      }

      try {
        // Get the ID token from the authenticated user
        const idToken = await currentUser.getIdToken();

        const response = await axios.post(
          CLOUD_FUNCTION_URL,
          {
            terms: terms.map((t) => t.term),
            types: terms.map((t) => t.type),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${idToken}`,
            },
          }
        );

        if (response.data && response.data.results) {
          const parsedResults = Object.values(response.data.results).flat();
          setDarkWebResults(parsedResults);
        } else {
          setError("No dark web results found.");
        }
      } catch (err) {
        console.error("Error during dark web search:", err);
        setError(err.response?.data?.error || "An error occurred while fetching dark web data.");
      }
    }

    setLoading(false);
  };

  const handleExportCSV = () => {
    if (darkWebResults.length === 0) {
      setError("No dark web data available to export.");
      return;
    }
    const csvContent = [
      Object.keys(darkWebResults[0] || {}).join(","),
      ...darkWebResults.map((row) =>
        Object.values(row)
          .map((value) => `"${value !== undefined ? value.toString().replace(/"/g, '""') : ""}"`)
          .join(",")
      ),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "darkweb_results.csv");
    link.style.visibility = "hidden";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleScheduleScan = async (searchTerm, email, dateTime) => {
    setError("");
    try {
      const response = await axios.post(`${FLASK_API_URL}/schedule-scan`, {
        email,
        searchTerm,
        dateTime,
      });

      if (response.data?.message) {
        alert(response.data.message);
      } else {
        setError("Unexpected response from the server.");
      }
    } catch (err) {
      console.error("Error scheduling scan:", err.response?.data || err.message);
      setError("Failed to schedule the scan. Please check your inputs and try again.");
    }
  };

  return (
    <Box
      sx={{
        p: 4,
        minHeight: "100vh",
        bgcolor: "background.default",
        color: "text.primary",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <IconButton
        onClick={() => navigate("/")}
        sx={{
          alignSelf: "flex-start",
          mb: 2,
        }}
      >
        <ArrowBackIcon sx={{ fontSize: "2rem", color: "#e63946" }} />
      </IconButton>

      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <Typography
          variant="h3"
          sx={{
            mb: 4,
            textAlign: "center",
            fontFamily: "'Montserrat', sans-serif",
            fontWeight: 700,
            background: "linear-gradient(135deg, #e63946, #f4a261)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          CTM (Cyber Threat Monitoring)
        </Typography>
      </motion.div>

      {/* Search Mode Selector */}
      <FormControl sx={{ mb: 3, width: "100%", maxWidth: "600px" }}>
        <InputLabel>Search Mode</InputLabel>
        <Select
          value={searchMode}
          onChange={(e) => setSearchMode(e.target.value)}
        >
          <MenuItem value="general">General Search</MenuItem>
          <MenuItem value="darkweb">Dark Web Monitoring</MenuItem>
        </Select>
      </FormControl>

      {/* General or Dark Web Inputs */}
      {searchMode === "general" ? (
        <Box sx={{ mb: 3, width: "100%", maxWidth: "600px" }}>
          <TextField
            fullWidth
            label="Enter Keywords for Search"
            value={keywords}
            onChange={(e) => setKeywords(e.target.value)}
            variant="outlined"
          />
        </Box>
      ) : (
        <Box
          sx={{
            display: "grid",
            gap: 2,
            mb: 4,
            width: "100%",
            maxWidth: "600px",
            gridTemplateColumns: "1fr 1fr",
          }}
        >
          {Object.keys(darkWebTerms).map((type) => (
            <TextField
              key={type}
              label={`Search by ${type}`}
              value={darkWebTerms[type]}
              onChange={(e) =>
                setDarkWebTerms((prev) => ({
                  ...prev,
                  [type]: e.target.value,
                }))
              }
            />
          ))}
        </Box>
      )}

      {/* Action Buttons */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: 2,
          mt: 2,
          mb: 3,
        }}
      >
        <Button
          variant="contained"
          onClick={() => setOpenScheduleModal(true)}
          sx={{
            backgroundColor: "#e63946",
            "&:hover": { backgroundColor: "#c72c3e" },
          }}
        >
          Schedule Scan
        </Button>
        <Button
          variant="contained"
          onClick={handleSearch}
          disabled={loading}
          sx={{
            backgroundColor: "#e63946",
            "&:hover": { backgroundColor: "#c72c3e" },
          }}
        >
          Search
        </Button>
      </Box>

      <ScheduleScanModal
        open={openScheduleModal}
        handleClose={() => setOpenScheduleModal(false)}
        handleSchedule={handleScheduleScan}
      />

      {/* Loading & Error Alerts */}
      {loading && (
        <Box
          sx={{
            mt: 4,
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
          }}
        >
          {/* 2) Use DecryptingText instead of or alongside the spinner */}
          <DecryptingText text="SCANNING THE DARK WEB AND EXTRACTING INTEL..." />
        </Box>
      )}

      {error && (
        <Alert severity="error" sx={{ mt: 4, mb: 4 }}>
          {error}
        </Alert>
      )}

      {/* GENERAL SEARCH RESULTS TABLE */}
      {searchMode === "general" && searchResults.length > 0 && (
        <>
          <Typography variant="h6" sx={{ mb: 2 }}>
            General Search Results:
          </Typography>
          <TableContainer component={Paper} sx={{ mb: 4 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Source</TableCell>
                  <TableCell>Details</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {searchResults.map(({ source, results }, index) => (
                  <React.Fragment key={index}>
                    {results.map((result, idx) => (
                      <TableRow key={idx}>
                        <TableCell>{source}</TableCell>
                        <TableCell>
                          {/* HUNTER DOMAIN */}
                          {source === "hunter_domain" ? (
                            <Box>
                              <Typography>
                                <strong>Email Address:</strong> {result.value || "N/A"} <br />
                                <strong>First Name:</strong> {result.first_name || "N/A"} <br />
                                <strong>Last Name:</strong> {result.last_name || "N/A"} <br />
                                <strong>Confidence:</strong> {result.confidence || "N/A"} <br />
                                <strong>Position:</strong> {result.position || "N/A"} <br />
                                <strong>Department:</strong> {result.department || "N/A"} <br />
                                <strong>LinkedIn:</strong> {result.linkedin || "N/A"} <br />
                                {result.verification && (
                                  <>
                                    <strong>Verification Status:</strong>{" "}
                                    {result.verification.status || "N/A"}
                                  </>
                                )}
                              </Typography>
                            </Box>
                          ) : source === "hunter_email" ? (
                            // HUNTER EMAIL
                            <Box>
                              <Typography>
                                <strong>Email:</strong> {result.email || "N/A"} <br />
                                <strong>Score:</strong> {result.score || "N/A"} <br />
                                <strong>Domain:</strong> {result.domain || "N/A"} <br />
                                <strong>Position:</strong> {result.position || "N/A"} <br />
                                <strong>Company:</strong> {result.company || "N/A"} <br />
                              </Typography>
                            </Box>
                          ) : source === "reddit" ? (
                            // REDDIT
                            <Box>
                              <Typography>
                                <strong>Title:</strong> {result.title || "N/A"} <br />
                                <strong>URL:</strong>{" "}
                                <a
                                  href={result.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{ color: "#e63946", textDecoration: "underline" }}
                                >
                                  {result.url || "No URL"}
                                </a>{" "}
                                <br />
                                <strong>Date:</strong> {result.date || "N/A"} <br />
                                <strong>Author:</strong> {result.author || "Deleted"} <br />
                                <strong>Subreddit:</strong> {result.subreddit || "N/A"} <br />
                                <strong>Comments:</strong> {result.num_comments || 0} <br />
                                <strong>Upvote Ratio:</strong> {result.upvote_ratio || "N/A"} <br />
                                <strong>Score:</strong> {result.score || 0} <br />
                                <strong>Post Type:</strong> {result.post_type || "N/A"} <br />
                                <strong>NSFW:</strong> {result.nsfw ? "Yes" : "No"} <br />
                                <strong>Flair:</strong> {result.flair || "None"} <br />
                                <strong>Stickied:</strong> {result.is_stickied ? "Yes" : "No"} <br />
                                <strong>Locked:</strong> {result.is_locked ? "Yes" : "No"} <br />
                              </Typography>
                            </Box>
                          ) : source === "shodan" ? (
                            // SHODAN
                            <Box>
                              <Typography>
                                <strong>IP:</strong> {result.ip || "N/A"} <br />
                                <strong>Port:</strong> {result.port || "N/A"} <br />
                                <strong>Org:</strong> {result.org || "N/A"} <br />
                                <strong>ISP:</strong> {result.isp || "N/A"} <br />
                                <strong>Location:</strong> {result.location || "Unknown"} <br />
                                {result.vulns && result.vulns.length > 0 ? (
                                  <Box>
                                    <strong>Vulnerabilities:</strong>
                                    <ul>
                                      {result.vulns.map((vuln, vIndex) => (
                                        <li key={vIndex}>
                                          <a
                                            href={`https://cve.mitre.org/cgi-bin/cvename.cgi?name=${vuln}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ color: "#e63946" }}
                                          >
                                            {vuln}
                                          </a>
                                        </li>
                                      ))}
                                    </ul>
                                  </Box>
                                ) : (
                                  <Typography>No known vulnerabilities.</Typography>
                                )}
                              </Typography>
                            </Box>
                          ) : (
                            // DEFAULT FALLBACK
                            <>
                              <Typography>{result.title || result.value || "N/A"}</Typography>
                              {result.url && (
                                <a
                                  href={result.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{ color: "#e63946", textDecoration: "underline" }}
                                >
                                  {result.url}
                                </a>
                              )}
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Typography variant="h6">Summary:</Typography>
          <Paper sx={{ p: 2, mb: 4 }}>{summary}</Paper>
        </>
      )}

      {/* DARK WEB RESULTS */}
      {searchMode === "darkweb" && darkWebResults.length > 0 && (
        <>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Dark Web Results:
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 3 }}>
            <Button
              variant="contained"
              onClick={handleExportCSV}
              sx={{
                backgroundColor: "#e63946",
                "&:hover": { backgroundColor: "#c72c3e" },
              }}
            >
              Export CSV
            </Button>
          </Box>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {darkWebResults[0] &&
                    Object.keys(darkWebResults[0]).map((key) => (
                      <TableCell key={key}>
                        {key.charAt(0).toUpperCase() + key.slice(1)}
                      </TableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {darkWebResults.map((result, index) => (
                  <TableRow key={index}>
                    {Object.values(result).map((value, idx) => (
                      <TableCell key={idx}>
                        {typeof value === "string" && value.startsWith("http") ? (
                          <a
                            href={value}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "#e63946", textDecoration: "underline" }}
                          >
                            {value}
                          </a>
                        ) : (
                          value || "N/A"
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Box>
  );
};

export default CTM;

import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Snackbar,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  Alert,
  IconButton,
} from "@mui/material";
import { motion } from "framer-motion";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../context/firebase";
import { useAuth } from "../context/AuthContext";

const AdminUserManagement = () => {
  const [email, setEmail] = useState("");
  const [domain, setDomain] = useState("");
  const [role, setRole] = useState("viewer");
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const handleAddUser = async () => {
    if (!email || !domain) {
      setError("Email and Domain are required.");
      return;
    }

    try {
      await addDoc(collection(db, "allowedusers"), {
        email,
        domain,
        role,
        allowed: true,
      });
      setShowSnackbar(true);
      setEmail("");
      setDomain("");
      setRole("viewer");
    } catch (err) {
      setError("Failed to add user. Please try again.");
      console.error(err);
    }
  };

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  return (
    <Box
      sx={{
        py: 6,
        px: 2,
        minHeight: "100vh",
        bgcolor: "background.default",
        color: "text.primary",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflowY: "auto",
      }}
    >
      {/* Back to Dashboard Button */}
      <IconButton
        onClick={() => navigate("/")}
        sx={{
          alignSelf: "flex-start",
          mb: 2,
        }}
      >
        <ArrowBackIcon sx={{ fontSize: "2rem", color: "#e63946" }} />
      </IconButton>

      {/* Title */}
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        style={{ textAlign: "center" }}
      >
        <Typography
          variant="h3"
          sx={{
            mb: 4,
            fontFamily: "'Montserrat', sans-serif",
            fontWeight: 700,
            background: "linear-gradient(135deg, #e63946, #f4a261)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Admin User Management
        </Typography>
      </motion.div>

      {/* Input Fields */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
          width: "100%",
          maxWidth: "500px",
          mb: 4,
        }}
      >
        <TextField
          label="Email Address"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
        />
        <TextField
          label="Domain"
          variant="outlined"
          value={domain}
          onChange={(e) => setDomain(e.target.value)}
          fullWidth
        />
        <FormControl fullWidth>
          <InputLabel>Role</InputLabel>
          <Select
            value={role}
            onChange={(e) => setRole(e.target.value)}
            sx={{ color: "text.primary" }}
          >
            <MenuItem value="viewer">Viewer</MenuItem>
            <MenuItem value="admin">Admin</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {/* Add User Button */}
      <Button
        variant="contained"
        color="primary"
        onClick={handleAddUser}
        sx={{
          mb: 3,
          backgroundColor: "#2196f3",
          "&:hover": { backgroundColor: "#1976d2" },
        }}
      >
        Add User
      </Button>

      {/* Error Alert */}
      {error && (
        <Box sx={{ mb: 2 }}>
          <Alert severity="error" onClose={() => setError("")}>
            {error}
          </Alert>
        </Box>
      )}

      {/* Snackbar */}
      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message="User added successfully!"
      />
    </Box>
  );
};

export default AdminUserManagement;

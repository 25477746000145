import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Box, Grid, Card, CardContent, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { motion, AnimatePresence } from "framer-motion";
import { FaShieldAlt, FaFileUpload, FaNetworkWired, FaBug, FaTerminal, FaUsers, FaBuilding, FaEye } from "react-icons/fa";

function Dashboard() {
  const theme = useTheme();
  const [isFinalText, setIsFinalText] = useState(false);

  useEffect(() => {
    // Set final text after 3 seconds
    const timer = setTimeout(() => setIsFinalText(true), 3000);
    return () => clearTimeout(timer);
  }, []);

  const features = [
    { name: "Dark Web Monitoring", icon: <FaEye />, link: "/dark-web-monitoring" }, // New feature
    { name: "Dark Web Recon", icon: <FaShieldAlt />, link: "/dark-web-recon" },
    { name: "Secure File Upload", icon: <FaFileUpload />, link: "/secure-file-upload" },
    { name: "DAST", icon: <FaBug />, link: "/dast" },
    { name: "Network Scanning", icon: <FaNetworkWired />, link: "/network-scanning" },
    { name: "CTM", icon: <FaTerminal />, link: "/ctm" },
    { name: "Admin User Management", icon: <FaUsers />, link: "/admin-management" },
    { name: "Admin Client Management", icon: <FaBuilding />, link: "/admin-client-management" },
  ];

  return (
    <Box
      sx={{
        p: 4,
        minHeight: "100vh",
        bgcolor: "background.default",
        color: "text.primary",
      }}
    >
      {/* Fading Header */}
      <Box
        sx={{
          mb: 4,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100px", // Ensures consistent spacing
        }}
      >
        <AnimatePresence mode="wait">
          {!isFinalText && (
            <motion.div
              key="welcome"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 1 }}
            >
              <Typography
                variant="h3"
                sx={{
                  textAlign: "center",
                  fontFamily: "'Montserrat', sans-serif",
                  fontWeight: 700,
                  fontSize: "2.5rem",
                  background: theme.palette.mode === "dark"
                    ? "linear-gradient(135deg, #e63946, #f4a261)"
                    : "linear-gradient(135deg, #ff7f50, #ff4500)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  textShadow: theme.palette.mode === "dark"
                    ? "2px 2px 5px rgba(0,0,0,0.5)"
                    : "2px 2px 5px rgba(255,255,255,0.3)",
                }}
              >
                Welcome to Shield Vault
              </Typography>
            </motion.div>
          )}
          {isFinalText && (
            <motion.div
              key="final-text"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
            >
              <Typography
                variant="h3"
                sx={{
                  textAlign: "center",
                  fontFamily: "'Orbitron', sans-serif",
                  fontWeight: 900,
                  fontSize: "3rem",
                  color: theme.palette.mode === "dark" ? "#e63946" : "#ff4500",
                  textShadow: "4px 4px 10px rgba(0,0,0,0.3)",
                }}
              >
                Offensive Security Operations Platform
              </Typography>
            </motion.div>
          )}
        </AnimatePresence>
      </Box>

      {/* Features */}
      <Grid container spacing={3}>
        {features.map((feature, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <Link
                to={feature.link}
                style={{
                  textDecoration: "none",
                }}
              >
                <Card
                  sx={{
                    bgcolor: "background.paper",
                    color: "text.primary",
                    boxShadow: "0 4px 12px rgba(0,0,0,0.2)",
                    borderRadius: "12px",
                    textAlign: "center",
                    p: 3,
                  }}
                >
                  <Box
                    sx={{
                      fontSize: "2.5rem",
                      mb: 2,
                      color: "primary.main",
                    }}
                  >
                    {feature.icon}
                  </Box>
                  <CardContent>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "bold",
                        color: "text.primary",
                      }}
                    >
                      {feature.name}
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </motion.div>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default Dashboard;

import React, { useState } from "react";
import { Box, Typography, Button, CircularProgress, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title } from "chart.js";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title);

function NessusUpload() {
  const [fileName, setFileName] = useState(null);
  const [loading, setLoading] = useState(false);
  const [parsedData, setParsedData] = useState([]);
  const [severityCounts, setSeverityCounts] = useState({});
  const [appName, setAppName] = useState("");

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      setLoading(true);

      try {
        const fileContent = await file.text();
        const parsedResults = parseNessusFile(fileContent);
        setParsedData(parsedResults);

        const appNameFromFile = file.name.split("_")[0];
        setAppName(appNameFromFile);

        const counts = parsedResults.reduce((acc, host) => {
          host.vulnerabilities.forEach((vuln) => {
            acc[vuln.severity] = (acc[vuln.severity] || 0) + 1;
          });
          return acc;
        }, {});
        setSeverityCounts(counts);
      } catch (error) {
        console.error("Error parsing file:", error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const parseNessusFile = (xmlString) => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlString, "application/xml");

    const hosts = [...xmlDoc.getElementsByTagName("ReportHost")];
    const findings = hosts.map((host) => {
      const hostname = host.getAttribute("name");
      const vulnerabilities = [...host.getElementsByTagName("ReportItem")].map((item) => ({
        name: item.getAttribute("pluginName"),
        severity: item.getAttribute("severity"),
        description: item.getElementsByTagName("description")[0]?.textContent || "No description available",
      }));
      return { hostname, vulnerabilities };
    });

    return findings;
  };

  const renderChart = () => {
    if (Object.keys(severityCounts).length === 0) return null;

    const chartData = {
      labels: ["Info", "Low", "Medium", "High", "Critical"],
      datasets: [
        {
          label: "Vulnerabilities by Severity",
          data: [
            severityCounts["0"] || 0,
            severityCounts["1"] || 0,
            severityCounts["2"] || 0,
            severityCounts["3"] || 0,
            severityCounts["4"] || 0,
          ],
          backgroundColor: ["#90caf9", "#4caf50", "#ffb74d", "#e57373", "#f44336"],
        },
      ],
    };

    return (
      <Box sx={{ width: "100%", maxWidth: "400px", mb: 4 }}>
        <Bar data={chartData} />
      </Box>
    );
  };

  const renderVulnerabilities = () => {
    if (!parsedData || parsedData.length === 0) {
      return (
        <Typography variant="body1" sx={{ mt: 4, color: "text.secondary" }}>
          No vulnerabilities found.
        </Typography>
      );
    }

    const groupedVulnerabilities = parsedData.reduce((acc, host) => {
      host.vulnerabilities.forEach((vuln) => {
        if (!acc[vuln.severity]) acc[vuln.severity] = [];
        acc[vuln.severity].push({ ...vuln, hostname: host.hostname });
      });
      return acc;
    }, {});

    const severityColors = {
      "0": "#90caf9", // Info
      "1": "#4caf50", // Low
      "2": "#ffb74d", // Medium
      "3": "#e57373", // High
      "4": "#f44336", // Critical
    };

    return Object.keys(groupedVulnerabilities)
      .sort((a, b) => b - a)
      .map((severity) => (
        <Accordion key={severity} sx={{ mb: 2, borderColor: severityColors[severity] }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: severityColors[severity], color: "#fff" }}>
            <Typography variant="h6">
              {severity === "0"
                ? "Info"
                : severity === "1"
                ? "Low"
                : severity === "2"
                ? "Medium"
                : severity === "3"
                ? "High"
                : "Critical"}{" "}
              Vulnerabilities ({groupedVulnerabilities[severity].length})
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {groupedVulnerabilities[severity].map((vuln, idx) => (
              <Box key={idx} sx={{ mb: 2 }}>
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  {vuln.name} (Host: {vuln.hostname})
                </Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  {vuln.description}
                </Typography>
              </Box>
            ))}
          </AccordionDetails>
        </Accordion>
      ));
  };

  return (
    <Box
      sx={{
        p: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography variant="h5" sx={{ mb: 2 }}>
        Upload a .nessus File
      </Typography>
      <Button variant="contained" component="label" sx={{ mb: 3 }}>
        Upload File
        <input type="file" hidden accept=".nessus" onChange={handleFileUpload} />
      </Button>
      {fileName && (
        <Typography variant="body1" sx={{ mb: 2 }}>
          Uploaded: {fileName}
        </Typography>
      )}
      {appName && (
        <Typography variant="h6" sx={{ mt: 2, color: "primary.main" }}>
          App Scanned: {appName}
        </Typography>
      )}
      {loading && <CircularProgress />}
      {renderChart()}
      {renderVulnerabilities()}
    </Box>
  );
}

export default NessusUpload;

import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

function BackToDashboardButton() {
  const navigate = useNavigate();

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={() => navigate("/")}
      sx={{
        mt: 4,
        bgcolor: "primary.main",
        color: "white",
        "&:hover": {
          bgcolor: "primary.dark",
        },
      }}
    >
      Back to Dashboard
    </Button>
  );
}

export default BackToDashboardButton;

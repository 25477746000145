import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Alert,
  Snackbar,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  IconButton,
} from "@mui/material";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { motion } from "framer-motion";
import { FileDrop } from "react-file-drop";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { storage } from "../context/firebase";

// 1) Import your DecryptingText component
import DecryptingText from "../components/DecryptingText";

const SecureFileUpload = () => {
  const [files, setFiles] = useState([]);
  const [downloadURLs, setDownloadURLs] = useState([]);
  const [expiry, setExpiry] = useState("24h");
  const [showAlert, setShowAlert] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [error, setError] = useState("");

  // New states for the decrypt animation
  const [showDecryption, setShowDecryption] = useState(false);
  const [showLinks, setShowLinks] = useState(false);

  const navigate = useNavigate();

  const handleFileChange = (files) => {
    if (files) {
      const selectedFiles = Array.from(files).slice(0, 2); // Allow up to 2 files
      setFiles(selectedFiles);
      setError(""); // Clear any previous errors
    }
  };

  const handleUpload = () => {
    if (files.length === 0) {
      setError("Please select files to upload.");
      return;
    }

    // Reset states
    setDownloadURLs([]);
    setShowAlert(false);
    setShowDecryption(false);
    setShowLinks(false);

    const uploadedURLs = [];
    files.forEach((file) => {
      const storageRef = ref(storage, `uploads/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        null,
        (error) => {
          console.error("Upload error:", error);
          setError("Failed to upload files. Please try again.");
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            uploadedURLs.push({ url: downloadURL, name: file.name });
            if (uploadedURLs.length === files.length) {
              setDownloadURLs(uploadedURLs);
              setShowAlert(true);
              // After upload completes, start the 'decrypting' effect
              setShowDecryption(true);
            }
          });
        }
      );
    });
  };

  const handleDecryptionComplete = () => {
    // Once decrypting finishes, we reveal the links
    setShowLinks(true);
  };

  const handleCopyLink = (url) => {
    navigator.clipboard.writeText(url);
    setShowSnackbar(true);
  };

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  return (
    <Box
      sx={{
        // A bit more vertical space for breathing
        py: 6,
        px: 2,
        minHeight: "100vh",
        bgcolor: "background.default",
        color: "text.primary",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // Allows us to scroll if the content grows tall
        overflowY: "auto",
      }}
    >
      {/* Back to Dashboard Button */}
      <IconButton
        onClick={() => navigate("/")}
        sx={{
          alignSelf: "flex-start",
          mb: 2,
        }}
      >
        <ArrowBackIcon sx={{ fontSize: "2rem", color: "#e63946" }} />
      </IconButton>

      {/* Title */}
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        style={{ textAlign: "center" }}
      >
        <Typography
          variant="h3"
          sx={{
            mb: 4,
            fontFamily: "'Montserrat', sans-serif",
            fontWeight: 700,
            background: "linear-gradient(135deg, #e63946, #f4a261)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Secure File Upload
        </Typography>
      </motion.div>

      {/* File Drop Area */}
      <FileDrop onDrop={(files, event) => handleFileChange(files)}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            p: 4,
            border: "3px dashed #e63946",
            borderRadius: "12px",
            width: "100%",
            maxWidth: "600px",
            minHeight: "200px",
            textAlign: "center",
            background:
              "linear-gradient(135deg, rgba(230,57,70,0.1), rgba(244,162,97,0.1))",
            "&:hover": {
              background:
                "linear-gradient(135deg, rgba(230,57,70,0.2), rgba(244,162,97,0.2))",
              cursor: "pointer",
            },
            mb: 3,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: "#e63946",
              fontWeight: "bold",
              mb: 2,
              fontFamily: "'Montserrat', sans-serif",
            }}
          >
            Drag & Drop Files Here
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: "#aaa",
              fontFamily: "'Roboto', sans-serif",
              maxWidth: "400px",
            }}
          >
            Only .pdf, .doc(x), .xls(x), .ppt(x), .csv, .txt, & .zip files are
            allowed (max 50MB).
          </Typography>

          {files.length > 0 && (
            <Box
              sx={{
                mt: 2,
                p: 2,
                border: "1px solid #e63946",
                borderRadius: "8px",
                width: "100%",
                maxWidth: "90%",
                bgcolor: "rgba(230,57,70,0.05)",
              }}
            >
              <Typography variant="body1" sx={{ color: "#ccc", mb: 1 }}>
                Selected Files:
              </Typography>
              <ul style={{ margin: 0, paddingLeft: "1.5em", textAlign: "left" }}>
                {files.map((file, index) => (
                  <li key={index} style={{ marginBottom: "4px" }}>
                    <Typography variant="body2" sx={{ color: "#fff" }}>
                      {file.name}
                    </Typography>
                  </li>
                ))}
              </ul>
            </Box>
          )}
        </Box>
      </FileDrop>

      {/* Select Files Button */}
      <Button
        variant="contained"
        component="label"
        sx={{
          mb: 2,
          backgroundColor: "#e63946",
          "&:hover": { backgroundColor: "#c72c3e" },
        }}
      >
        Select Files
        <input
          type="file"
          hidden
          multiple
          onChange={(e) => handleFileChange(e.target.files)}
        />
      </Button>

      {/* Expiry Dropdown */}
      <FormControl
        fullWidth
        sx={{
          mb: 3,
          maxWidth: "300px",
          textAlign: "left",
        }}
      >
        <InputLabel>Link Expiry</InputLabel>
        <Select
          value={expiry}
          onChange={(e) => setExpiry(e.target.value)}
          sx={{ color: "#fff" }}
        >
          <MenuItem value="24h">24 Hours</MenuItem>
          <MenuItem value="48h">48 Hours</MenuItem>
          <MenuItem value="3d">3 Days</MenuItem>
          <MenuItem value="5d">5 Days</MenuItem>
          <MenuItem value="burn">Burn after reading</MenuItem>
        </Select>
      </FormControl>

      {/* Upload Button */}
      <Button
        variant="contained"
        color="primary"
        onClick={handleUpload}
        sx={{ mb: 3, backgroundColor: "#2196f3", "&:hover": { backgroundColor: "#1976d2" } }}
      >
        Upload
      </Button>

      {error && (
        <Box sx={{ mb: 2 }}>
          <Alert severity="error">{error}</Alert>
        </Box>
      )}

      {/* Decryption Message */}
      {showDecryption && !showLinks && (
        <Box sx={{ mt: 3 }}>
          <DecryptingText
            text="ENCRYPTING DOWNLOAD LINKS... PLEASE WAIT"
            onComplete={handleDecryptionComplete}
          />
        </Box>
      )}

      {/* Download Links */}
      {showLinks && downloadURLs.length > 0 && (
        <Box sx={{ mt: 3, maxWidth: "600px" }}>
          <Typography variant="h6" sx={{ mb: 2, color: "#f4a261" }}>
            Secure Download Links:
          </Typography>
          {downloadURLs.map((file, index) => (
            <Box
              key={index}
              sx={{
                mt: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 2,
              }}
            >
              <Typography variant="body2" sx={{ color: "#fff" }}>
                <a
                  href={file.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#e63946", textDecoration: "underline" }}
                >
                  {file.name}
                </a>
              </Typography>
              <Button
                variant="outlined"
                onClick={() => handleCopyLink(file.url)}
                sx={{
                  borderColor: "#e63946",
                  color: "#e63946",
                  "&:hover": {
                    borderColor: "#c72c3e",
                    color: "#c72c3e",
                  },
                }}
              >
                Copy Link
              </Button>
            </Box>
          ))}
        </Box>
      )}

      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message="Copied to clipboard"
      />

      {showAlert && (
        <Snackbar
          open={showAlert}
          autoHideDuration={6000}
          onClose={() => setShowAlert(false)}
        >
          <Alert severity="success" onClose={() => setShowAlert(false)}>
            Files uploaded successfully. Encryption in progress...
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default SecureFileUpload;

import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Snackbar,
  Alert,
  Card,
  CardContent,
} from "@mui/material";
import { motion } from "framer-motion";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { db } from "../context/firebase";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

const AdminClientManagement = () => {
  const [clientName, setClientName] = useState("");
  const [clientDomain, setClientDomain] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleAddClient = async () => {
    if (!clientName || !clientDomain) {
      setErrorMessage("Both fields are required.");
      return;
    }

    try {
      const clientRef = doc(db, "clients", clientDomain);
      const clientDoc = await getDoc(clientRef);

      if (clientDoc.exists()) {
        setErrorMessage("Client already exists. No changes made.");
      } else {
        await setDoc(clientRef, {
          name: clientName,
          domain: clientDomain,
        });
        setSuccessMessage(`Client "${clientName}" added successfully.`);
      }

      setClientName("");
      setClientDomain("");
    } catch (error) {
      console.error("Error adding client:", error);
      setErrorMessage("Failed to add client. Please try again.");
    }
  };

  return (
    <Box
      sx={{
        py: 6,
        px: 2,
        minHeight: "100vh",
        bgcolor: "background.default",
        color: "text.primary",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {/* Back Button */}
      <Button
        onClick={() => navigate("/admin-management")}
        startIcon={<ArrowBackIcon />}
        sx={{
          alignSelf: "flex-start",
          mb: 4,
          color: "primary.main",
          textTransform: "none",
        }}
      >
        Back to Admin Management
      </Button>

      {/* Title */}
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <Typography
          variant="h3"
          sx={{
            textAlign: "center",
            fontFamily: "'Montserrat', sans-serif",
            fontWeight: 700,
            mb: 4,
            background: "linear-gradient(135deg, #e63946, #f4a261)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Admin Client Management
        </Typography>
      </motion.div>

      {/* Input Form */}
      <Grid container spacing={3} sx={{ maxWidth: "600px" }}>
        <Grid item xs={12}>
          <TextField
            label="Company Name"
            variant="outlined"
            fullWidth
            value={clientName}
            onChange={(e) => setClientName(e.target.value)}
            sx={{ bgcolor: "background.paper" }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Domain"
            variant="outlined"
            fullWidth
            value={clientDomain}
            onChange={(e) => setClientDomain(e.target.value)}
            sx={{ bgcolor: "background.paper" }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleAddClient}
            sx={{
              bgcolor: "#e63946",
              "&:hover": { bgcolor: "#c72c3e" },
            }}
          >
            Add Client
          </Button>
        </Grid>
      </Grid>

      {/* Information Section */}
      <Box
        sx={{
          mt: 4,
          maxWidth: "600px",
          width: "100%",
          bgcolor: "background.paper",
          borderRadius: "12px",
          boxShadow: "0 4px 12px rgba(0,0,0,0.2)",
        }}
      >
        <Card>
          <CardContent>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                mb: 2,
                color: "text.primary",
              }}
            >
              How It Works
            </Typography>
            <Typography variant="body1" sx={{ color: "text.secondary", mb: 1 }}>
              1. A client is identified by their **domain** (e.g.,
              <strong> example.com</strong>) and **company name**.
            </Typography>
            <Typography variant="body1" sx={{ color: "text.secondary", mb: 1 }}>
              2. Before adding a client, ensure all relevant users have already
              been created with their domain and role.
            </Typography>
            <Typography variant="body1" sx={{ color: "text.secondary", mb: 1 }}>
              3. When a user logs in, their **domain** determines which client's
              data they can view:
              <ul>
                <li>
                  <strong>Viewer Role:</strong> Can only access dark web stats
                  for their assigned domain.
                </li>
                <li>
                  <strong>Admin Role:</strong> Has full access to all client
                  data.
                </li>
              </ul>
            </Typography>
            <Typography variant="body1" sx={{ color: "text.secondary" }}>
              4. Adding a client does not automatically create users. User
              management must be done separately through **Admin User
              Management**.
            </Typography>
          </CardContent>
        </Card>
      </Box>

      {/* Snackbar Notifications */}
      <Snackbar
        open={!!successMessage}
        autoHideDuration={4000}
        onClose={() => setSuccessMessage("")}
      >
        <Alert
          severity="success"
          onClose={() => setSuccessMessage("")}
          sx={{ width: "100%" }}
        >
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={4000}
        onClose={() => setErrorMessage("")}
      >
        <Alert
          severity="error"
          onClose={() => setErrorMessage("")}
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AdminClientManagement;

import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import DarkWebMonitoring from "./pages/DarkWebMonitoring"; // Added
import DarkWebRecon from "./pages/DarkWebRecon";
import SecureFileUpload from "./pages/SecureFileUpload";
import DAST from "./pages/DAST";
import NetworkScanning from "./pages/NetworkScanning";
import CTM from "./pages/CTM";
import AdminUserManagement from "./pages/AdminUserManagement";
import AdminClientManagement from "./pages/AdminClientManagement";
import Header from "./components/Header";
import PrivateRoute from "./components/PrivateRoute";
import { AuthProvider } from "./context/AuthContext";
import { ClientProvider } from "./context/ClientContext"; // Import ClientContext

function App() {
  return (
    <AuthProvider>
      <ClientProvider> {/* Wrap everything in ClientProvider */}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Router>
            <Header /> {/* Header automatically consumes ClientContext */}
            <Routes>
              {/* Public route: login */}
              <Route path="/login" element={<Login />} />

              {/* Private routes */}
              <Route
                path="/"
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                }
              />
              <Route
                path="/dark-web-monitoring"
                element={
                  <PrivateRoute>
                    <DarkWebMonitoring />
                  </PrivateRoute>
                }
              />
              <Route
                path="/dark-web-recon"
                element={
                  <PrivateRoute>
                    <DarkWebRecon />
                  </PrivateRoute>
                }
              />
              <Route
                path="/secure-file-upload"
                element={
                  <PrivateRoute>
                    <SecureFileUpload />
                  </PrivateRoute>
                }
              />
              <Route
                path="/dast"
                element={
                  <PrivateRoute>
                    <DAST />
                  </PrivateRoute>
                }
              />
              <Route
                path="/network-scanning"
                element={
                  <PrivateRoute>
                    <NetworkScanning />
                  </PrivateRoute>
                }
              />
              <Route
                path="/ctm"
                element={
                  <PrivateRoute>
                    <CTM />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin-management"
                element={
                  <PrivateRoute>
                    <AdminUserManagement />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin-client-management"
                element={
                  <PrivateRoute>
                    <AdminClientManagement />
                  </PrivateRoute>
                }
              />

              {/* Catch-all route for unknown pages */}
              <Route path="*" element={<Navigate to="/login" replace />} />
            </Routes>
          </Router>
        </LocalizationProvider>
      </ClientProvider>
    </AuthProvider>
  );
}

export default App;

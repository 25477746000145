import React, { createContext, useContext, useState, useEffect } from "react";
import {
  onAuthStateChanged,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { auth, googleProvider, db } from "./firebase";

// Create the context
const AuthContext = createContext(null);

// Handy hook to access auth data
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export const AuthProvider = ({ children }) => {
  // Store the raw Firebase User object here
  const [currentUser, setCurrentUser] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [userDomain, setUserDomain] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      console.log("Auth state changed:", user); // Debugging
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, "allowedusers", user.email));
          console.log("User document fetched:", userDoc.data()); // Debugging
          if (userDoc.exists() && userDoc.data().allowed) {
            // Store the actual Firebase User object
            setCurrentUser(user);
            setUserRole(userDoc.data().role || "viewer");
            setUserDomain(userDoc.data().domain || "unknown");
          } else {
            console.warn("User not allowed or document not found.");
            await signOut(auth);
            setCurrentUser(null);
            setUserRole(null);
            setUserDomain(null);
          }
        } catch (error) {
          console.error("Error fetching user document:", error);
          setCurrentUser(null);
          setUserRole(null);
          setUserDomain(null);
        }
      } else {
        console.log("No user signed in."); // Debugging
        setCurrentUser(null);
        setUserRole(null);
        setUserDomain(null);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const loginWithGoogle = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      console.log("Google login successful.");
    } catch (error) {
      console.error("Error during Google login:", error);
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      console.log("User logged out.");
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  // Provide everything needed via context
  const value = {
    currentUser,   // The raw Firebase User (or null)
    userRole,
    userDomain,
    loginWithGoogle,
    logout,
    loading,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
